import React, {useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import Box from "@mui/material/Box";
import {Link, useLocation} from "@reach/router";
import {useSelector} from "react-redux";
import {AppButton} from "../btn";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            padding: "24px",
            position: "relative",
            zIndex: 10
        },

        item: {
            marginBottom: "16px"
        },

        link: {
            "&:hover": {
                textDecoration: "none"
            }
        }

    })
);

const Menu = () => {
    const  classList = useStyles();
    const location = useLocation();
    const menu = useSelector((state) => state.app.menu);

    return(
        <Box className={`${classList.root}`}>
            {menu.map((item, k) => {
                return (
                    <MenuItem
                        key={k}
                        title={`${item.name}`}
                        hasActive={ location.pathname === item.path}
                        path={item.path}
                        children={item.child}
                    />
                )
            })}
        </Box>
    )
}


const MenuItem = ({title, hasActive, path, children = []}) => {
    const  classList = useStyles();
    const [hasOpen, setHasOpen] = useState(false);

    const handleClick = () => {
        setHasOpen(!hasOpen);
    };

    return(
        <Box className={`${classList.item}`}>
            <Link to={path} className={`${classList.link}`} onClick={handleClick}>
                <AppButton
                    active={hasActive}
                    title={`${title}`}
                    color={"#00000000"}
                    pos={children.length ? 'space-between' : 'flex-start'}
                    hoverBtn={`#ffffff0d`}
                    hoverText={'#000'}
                    icon={children.length ? (hasOpen ? 'down-active' : 'down') : null}
                />
            </Link>
            {hasOpen ? children.map((item, k) => {
                return (
                    <ListItemMenu key={k} path={item.path} title={item.name}/>
                )
            }) : null}
        </Box>
    )
}

const ListItemMenu = ({path, title}) => {
    const  classList = useStyles();
    const location = useLocation();
    const active = location.pathname === path;
    return(
        <Link to={path} className={`${classList.link}`}>
            <AppButton
                active={active}
                title={`${title}`}
                color={"#00000000"}
                pos={'flex-start'}
                hoverBtn={`#ffffff0d`}
                hoverText={'#9C8EF4'}
                leftIcon={active ? `dot-active` : `dot`}
            />
        </Link>
    )
}

export default Menu;
