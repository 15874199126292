import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useFetchAuthUser, useLogout, useLogoutOnTokenExpire} from "../../helpers/hooks";

const auth = (Wrapped) => {
    return (props) => {
        const isAuth = useSelector((state) => state.app.auth.isAuth);
        const fetchAuthUser = useFetchAuthUser();
        const doLogout = useLogout();

        useLogoutOnTokenExpire();

        useEffect(() => {
            if (isAuth === true) {
                return;
            } else if (isAuth === false) {
                doLogout();
            } else {
                fetchAuthUser();
            }
        }, [isAuth]);

        if (isAuth !== true) {
            return null;
        }

        return <Wrapped {...props} />;
    };
};

export default auth;
