export const fetchAuthUserApiRoute = "auth/login-oauth";
export const fetchLogoutApiRoute = "auth/logout";


/* Calendars */
export const fetchCalendarsApiRoute = "google/list-calendar";
export const changeCalendarStatusApiRoute = "google/init-calendar-item";



/* Payment */
export const fetchOrdersApiRoute = "payment/fetch-orders";
export const fetchCellApiRoute = "payment/fetch-cell";

/* Users */
export const payApiRoute = "users/add-seats";
export const deleteCellApiRoute = "users/delete-seat";
export const fetchUsersApiRoute = "users/list";
export const saveUsersApiRoute = "users/save";
export const saveUserCompanyApiRoute = "users/save-company";


/* Cards */
export const savePaymentMethodApiRoute = "cards/save-payment-method";
export const fetchPaymentMethodApiRoute = "cards/fetch-payment-method";
export const deletePaymentMethodApiRoute = "cards/delete-payment-method";

/* SimPro */
export const fetchCompaniesApiRoute = "sim-pro/fetch-companies";
export const saveDefaultCompanyApiRoute = "sim-pro/save-default-company";
