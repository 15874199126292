export const selectAuthUser = (state) => state.app.auth.user;

// Catalog
export const selectCalendars = (state) => state.catalog.calendars;
export const selectOrders = (state) => state.payment.orders;

// Users
export const selectClientsList = (state) => state.clients.list;

// SimPro
export const selectCompaniesList = (state) => state.settings.simPro.companies;

// SimPro
export const selectCardsList = (state) => state.settings.cards.list;
