import React, {Fragment} from "react";
import {Redirect, Router} from "@reach/router";
import {RouteAgent, route, setRoutes} from "./core";
import NotFound from "../../http/pages/not_found";
import Loadable from "react-loadable";
import guest from "../../http/middleware/guest";
import auth from "../middleware/auth";
import {useSelector} from "react-redux";
import BlockLoaderV2 from "../../components/loadings/block-loader-v2";

const load = (importComponent) =>
    Loadable({
        loader: () =>
            new Promise((resolve) => {
                resolve(importComponent());
            }),
        loading: BlockLoaderV2,
    });

RouteAgent.group({middleware: [guest]}, () => {

    RouteAgent.group({prefix: "oauth"}, () => {
        RouteAgent.route({
            path: "authorize",
            page: load(() => import("../../http/pages/authorize")),
            name: "login",
        });
    });
}).break();

RouteAgent.group({middleware: [auth]}, () => {
    RouteAgent.route({
        path: "dashboard",
        page: load(() => import("../../http/pages/dashboard")),
        name: "dashboard",
    });
}).break();

RouteAgent.group({prefix: "dashboard", middleware: [auth]}, () => {

    RouteAgent.group({prefix: "home"}, () => {
        RouteAgent.route({
            path: "",
            page: load( () => import("../pages/calendar/list")),
            name: "home",
        });

    });

    RouteAgent.group({prefix: "calendar"}, () => {
        RouteAgent.route({
            path: "list",
            page: load( () => import("../pages/calendar/list")),
            name: "home",
        });
    });

    RouteAgent.group({prefix: "payment"}, () => {
        RouteAgent.route({
            path: "",
            page: load( () => import("../pages/payment")),
            name: "payment",
        });
    });
    RouteAgent.group({prefix: "users"}, () => {
        RouteAgent.route({
            path: "",
            page: load( () => import("../pages/clients")),
            name: "users",
        });
    });

    RouteAgent.group({prefix: "settings"}, () => {
        RouteAgent.route({
            path: "",
            page: load( () => import("../pages/settings/cards")),
            name: "cards",
        });

        RouteAgent.route({
            path: "cards",
            page: load( () => import("../pages/settings/cards")),
            name: "cards",
        });

        RouteAgent.route({
            path: "sim-pro",
            page: load( () => import("../pages/settings/sim_pro")),
            name: "sim_pro",
        });
    });

}).break();

RouteAgent.route({
    path: "page-404",
    page: load(() => import("../../http/pages/not_found")),
    name: "404",
});

const Home = () => {
    const isAuth = useSelector((state) => state.app.auth.isAuth);

    if (isAuth) {
        return <Redirect noThrow to={route("dashboard")} />;
    }

    return <Redirect noThrow to={route("login")} />;
};

function MainRouter(props) {
    return (
        <Fragment>
            {props.children}
            <Router>
                <Home path="/" />
                {setRoutes()}
                <NotFound default />
            </Router>
        </Fragment>
    );
}

export {MainRouter, route};
